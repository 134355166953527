<template>
  <div class="saleCashierReceipt">
    <el-scrollbar class="el-scrollbar_height" style="height:500px">
      <div class="marrt_10">
        <div class="dis_flex">
          <span
            class="flex_box text_center font_16"
            style="line-height:32px"
          >{{userInfo.EntityName}}</span>
        </div>
        <el-divider>
          <span class="font_12 color_gray">订单信息</span>
        </el-divider>

        <div class="dis_flex">
          <span class="flex_box font_12 color_gray text-left line_height_24">订单编号</span>
          <span
            class="font_12 text_right line_height_24"
            style="flex:3;"
          >{{saleOrderDetail.ID}}</span>
        </div>
        <div class="dis_flex">
          <span class="flex_box font_12 color_gray text-left line_height_24">下单时间</span>
          <span
            class="font_12 text_right line_height_24"
            style="flex:3;"
          >{{saleOrderDetail.BillDate}}</span>
        </div>
        <div class="dis_flex">
          <span class="flex_box font_12 color_gray text-left line_height_24">会员姓名</span>
          <span
            class="flex_box font_12 text_right line_height_24"
          >{{cashierReceipt.NameEncrypt?formatName(saleOrderDetail.Name) : saleOrderDetail.Name}}</span>
        </div>
        <div class="dis_flex">
          <span class="flex_box font_12 color_gray text-left line_height_24">会员手机号</span>
          <span
            class="flex_box font_12 text_right line_height_24"
          >{{cashierReceipt.MobileEncrypt?formatPhone(saleOrderDetail.PhoneNumber):saleOrderDetail.PhoneNumber}}</span>
        </div>
        <div class="dis_flex">
          <span class="flex_box font_12 color_gray text-left line_height_24">收银员</span>
          <span
            class="flex_box font_12 text_right line_height_24"
          >{{saleOrderDetail.EmployeeName}}</span>
        </div>
        <div v-if="cashierReceipt.EntityAddress" class="dis_flex">
          <span class="flex_box6 color_gray text-left line_height_24">地址：</span>
          <span class="flex_box font_12 text_right line_height_24">{{saleOrderDetail.AddressDetail}}</span>
        </div>
        <el-divider>
          <span class="font_12 color_gray">消费明细</span>
        </el-divider>
        <template v-for="(item,index) in saleOrderDetail.Project">
          <div :key="index + 'Project' + item.ProjectID">
            <div class="dis_flex">
              <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                {{index+1}} {{item.ProjectName}}
                <span
                  class="font_12"
                  size="mini"
                  v-if="item.IsLargess"
                >【赠】</span>
              </span>
              <span
                v-if="cashierReceipt.SaleGoodsOriginPrice"
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >￥{{item.Price | NumFormat}}</span>
            </div>
            <div class="dis_flex">
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >数量</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >{{item.Quantity}}</span>
            </div>
            <div
              class="dis_flex"
              v-if="item.PricePreferentialAmount!=0 && cashierReceipt.SalePromotions"
            >
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >手动改价</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-if="item.PricePreferentialAmount>0"
              >-￥{{item.PricePreferentialAmount | NumFormat}}</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-else
              >+￥{{mathAbsData(item.PricePreferentialAmount) | NumFormat}}</span>
            </div>
            <div
              class="dis_flex"
              v-if="item.CardPreferentialAmount!=0 && cashierReceipt.SalePromotions"
            >
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >卡优惠</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >-￥{{item.CardPreferentialAmount | NumFormat}}</span>
            </div>
            <div class="dis_flex">
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >小计</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-if="item.IsLargess"
              >￥0.00</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-else
              >￥{{item.TotalAmount | NumFormat}}</span>
            </div>
            <!-- <div v-if="cashierReceipt.Handler">
              
              <div v-for="(handle,handIndex) in saleOrderDetail.SaleBillHandler" :key="handIndex + '-' + handle.SaleHandlerID">
                <span class="flex_box6 color_gray text-left line_height_24">{{handle.SaleHandlerName}}</span>
                <div v-for="(emp,empIndex) in handle.Employee" :key="empIndex + '-+' + emp.EmployeeID">

                </div>

              </div>

            </div> -->
          </div>
        </template>
        <template v-for="(item,index) in saleOrderDetail.SavingCard">
          <div :key="index + 'SavingCard' + item.SavingCardID">
            <div class="dis_flex">
              <span
                class="font_12 color_gray text-left line_height_24"
                style="flex:2;"
              >{{index+1+saleOrderDetail.Project.length}} {{item.SavingCardName}}</span>
              <span
                v-if="cashierReceipt.SaleGoodsOriginPrice"
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >￥{{(item.Amount/item.Quantity || 0).toFixed(2) | NumFormat}}</span>
            </div>
            <div class="dis_flex" v-if="item.LargessPrice>0">
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >充值赠送</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >￥{{(item.LargessPrice/item.Quantity || 0).toFixed(2) | NumFormat}}</span>
            </div>
            <div class="dis_flex">
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >数量</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >{{item.Quantity}}</span>
            </div>
            <div v-if="cashierReceipt.SaleGoodsOriginPrice" class="dis_flex">
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >小计</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >￥{{item.Amount | NumFormat}}</span>
            </div>
          </div>
        </template>
        <template v-for="(item,index) in saleOrderDetail.TimeCard">
          <div :key="index+'TimeCard'+item.TimeCardID">
            <div class="dis_flex">
              <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length}} {{item.TimeCardName}}
                <span
                  class="font_12"
                  size="mini"
                  v-if="item.IsLargess"
                >【赠】</span>
              </span>
              <span
                v-if="cashierReceipt.SaleGoodsOriginPrice"
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >￥{{item.Price | NumFormat}}</span>
            </div>
            <div class="dis_flex">
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >数量</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >{{item.Quantity}}</span>
            </div>
            <div
              class="dis_flex"
              v-if="item.PricePreferentialAmount!=0  && cashierReceipt.SalePromotions"
            >
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >手动改价</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-if="item.PricePreferentialAmount>0"
              >-￥{{item.PricePreferentialAmount | NumFormat}}</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-else
              >+￥{{mathAbsData(item.PricePreferentialAmount) | NumFormat}}</span>
            </div>
            <div
              class="dis_flex"
              v-if="item.CardPreferentialAmount!=0  && cashierReceipt.SalePromotions"
            >
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >卡优惠</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >-￥{{item.CardPreferentialAmount | NumFormat}}</span>
            </div>
            <div class="dis_flex">
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >小计</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-if="item.IsLargess"
              >￥0.00</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-else
              >￥{{item.TotalAmount | NumFormat}}</span>
            </div>
          </div>
        </template>
        <template v-for="(item,index) in saleOrderDetail.GeneralCard">
          <div :key="index+'GeneralCard'+item.GeneralCardID">
            <div class="dis_flex">
              <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length+saleOrderDetail.TimeCard.length}} {{item.GeneralCardName}}
                <span
                  class="font_12"
                  size="mini"
                  v-if="item.IsLargess"
                >【赠】</span>
              </span>
              <span
                v-if="cashierReceipt.SaleGoodsOriginPrice"
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >￥{{item.Price | NumFormat}}</span>
            </div>
            <div class="dis_flex">
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >数量</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >{{item.Quantity}}</span>
            </div>
            <div
              class="dis_flex"
              v-if="item.PricePreferentialAmount!=0  && cashierReceipt.SalePromotions"
            >
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >手动改价</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-if="item.PricePreferentialAmount>0"
              >-￥{{item.PricePreferentialAmount | NumFormat}}</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-else
              >+￥{{mathAbsData(item.PricePreferentialAmount) | NumFormat}}</span>
            </div>
            <div
              class="dis_flex"
              v-if="item.CardPreferentialAmount!=0  && cashierReceipt.SalePromotions"
            >
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >卡优惠</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >-￥{{item.CardPreferentialAmount | NumFormat}}</span>
            </div>
            <div class="dis_flex">
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >小计</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-if="item.IsLargess"
              >￥0.00</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-else
              >￥{{item.TotalAmount | NumFormat}}</span>
            </div>
          </div>
        </template>
        <template v-for="(item,index) in saleOrderDetail.Product">
          <div :key="index+'Product'+item.ProductID">
            <div class="dis_flex">
              <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length+saleOrderDetail.TimeCard.length+saleOrderDetail.GeneralCard.length}} {{item.ProductName}}
                <span
                  class="font_12"
                  size="mini"
                  v-if="item.IsLargess"
                >【赠】</span>
              </span>
              <span
                v-if="cashierReceipt.SaleGoodsOriginPrice"
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >￥{{item.Price | NumFormat}}</span>
            </div>
            <div class="dis_flex">
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >数量</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >{{item.Quantity}}</span>
            </div>
            <div
              class="dis_flex"
              v-if="item.PricePreferentialAmount!=0  && cashierReceipt.SalePromotions"
            >
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >手动改价</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-if="item.PricePreferentialAmount>0"
              >-￥{{item.PricePreferentialAmount | NumFormat}}</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-else
              >+￥{{mathAbsData(item.PricePreferentialAmount) | NumFormat}}</span>
            </div>
            <div
              class="dis_flex"
              v-if="item.CardPreferentialAmount!=0  && cashierReceipt.SalePromotions"
            >
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >卡优惠</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >-￥{{item.CardPreferentialAmount | NumFormat}}</span>
            </div>
            <div class="dis_flex">
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >小计</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-if="item.IsLargess"
              >￥0.00</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-else
              >￥{{item.TotalAmount | NumFormat}}</span>
            </div>
          </div>
        </template>
        <template v-for="(item,index) in saleOrderDetail.PackageCard">
          <div :key="index+'PackageCard'+item.PackageCardID">
            <div class="dis_flex">
              <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length+saleOrderDetail.TimeCard.length+saleOrderDetail.GeneralCard.length + saleOrderDetail.Product.length}} {{item.PackageCardName}}
                <span
                  class="font_12"
                  size="mini"
                  v-if="item.IsLargess"
                >(赠)</span>
              </span>
              <span
                v-if="cashierReceipt.SaleGoodsOriginPrice"
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >￥{{item.Price | NumFormat}}</span>
            </div>
            <div class="dis_flex">
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >数量</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >{{item.Quantity}}</span>
            </div>
            <div
              class="dis_flex"
              v-if="item.PricePreferentialAmount!=0  && cashierReceipt.SalePromotions"
            >
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >手动改价</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-if="item.PricePreferentialAmount>0"
              >-￥{{item.PricePreferentialAmount | NumFormat}}</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-else
              >+￥{{mathAbsData(item.PricePreferentialAmount) | NumFormat}}</span>
            </div>
            <div
              class="dis_flex"
              v-if="item.CardPreferentialAmount!=0  && cashierReceipt.SalePromotions"
            >
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >卡优惠</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
              >-￥{{item.CardPreferentialAmount | NumFormat}}</span>
            </div>
            <div class="dis_flex">
              <span
                class="font_12 color_gray text-left line_height_24 marlt_10"
                style="flex:2;"
              >小计</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-if="item.IsLargess"
              >￥0.00</span>
              <span
                class="font_12 text_right line_height_24"
                style="flex:1;"
                v-else
              >￥{{item.TotalAmount | NumFormat}}</span>
            </div>
          </div>
        </template>
        <el-divider class="sell-el-divider"></el-divider>
        <div class="dis_flex">
          <span class="flex_box font_12 color_gray text-left line_height_24">合计</span>
          <span
            class="flex_box font_12 text_right line_height_24"
          >￥{{saleOrderDetail.TotalAmount | NumFormat}}</span>
        </div>
        <div
          v-if="saleOrderDetail.SaleBillPay && saleOrderDetail.SaleBillPay.length > 0 "
          class="dis_flex font_12"
        >
          <span class="flex_box6 color_gray text-left line_height_24">付款：</span>
          <div class="flex_box">
            <div
              class="dis_flex"
              v-for="pay in saleOrderDetail.SaleBillPay"
              :key="pay.ID + 'pay'"
            >
              <span class="flex_box color_gray line_height_24">{{pay.Name}}</span>
              <span class="flex_box text_right line_height_24">¥ {{pay.Amount | NumFormat}}</span>
            </div>
          </div>
        </div>
        <!--  -->
        <div
          v-if="saleOrderDetail.SaleBillPaySavingCardDeduction && saleOrderDetail.SaleBillPaySavingCardDeduction.length > 0"
          class="dis_flex font_12"
        >
          <span class="flex_box6 color_gray text-left line_height_24">卡抵扣：</span>
          <div class="flex_box">
            <div
              class="dis_flex flex_box"
              v-for="cardPay in saleOrderDetail.SaleBillPaySavingCardDeduction"
              :key="cardPay.ID + 'cardPay'"
            >
              <span class="flex_box color_gray line_height_24">{{cardPay.Name}}</span>
              <span
                class="flex_box text_right line_height_24"
              >¥ {{cardPay.TotalAmount | NumFormat}}</span>
            </div>
          </div>
        </div>
        <div
          class="dis_flex"
          v-if="saleOrderDetail.PricePreferentialAmount!=0  && cashierReceipt.SalePromotions"
        >
          <span class="flex_box font_12 color_gray text-left line_height_24">手动改价</span>
          <span
            class="flex_box font_12 text_right line_height_24"
            v-if="saleOrderDetail.PricePreferentialAmount>0"
          >-￥{{saleOrderDetail.PricePreferentialAmount | NumFormat}}</span>
          <span
            class="flex_box font_12 text_right line_height_24"
            v-else
          >+￥{{mathAbsData(saleOrderDetail.PricePreferentialAmount) | NumFormat}}</span>
        </div>
        <div
          class="dis_flex"
          v-if="saleOrderDetail.CardPreferentialAmount>0 && cashierReceipt.SalePromotions"
        >
          <span class="flex_box font_12 color_gray text-left line_height_24">卡优惠</span>
          <span
            class="flex_box font_12 text_right line_height_24"
          >-￥{{saleOrderDetail.CardPreferentialAmount | NumFormat}}</span>
        </div>
        <el-divider class="sell-el-divider"></el-divider>
        <div
          class="dis_flex flex_dir_column font_14 font_weight_600 flex_y_center color_999 padbm_10"
        >
          <span>{{cashierReceipt.WriteTextFirst}}</span>
          <span>{{cashierReceipt.WriteTextSecond}}</span>
        </div>
        <div class="dis_flex font_12">
          <span class="flex_box6 color_gray text-left line_height_24">签字：</span>
        </div>

      </div>
    </el-scrollbar>
  </div>
</template>

<script>

import printReceipt from "@/components/js/print";

export default {
 name: 'saleCashierReceipt',
  props:{
    saleOrderDetail:{
      type:Object,
      require:true
    },
    userInfo:{
      type:Object,
      require:true,
    },
    cashierReceipt:{
      type:Object,
      require:true,
    }
  },
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {

    }
  },
   /**计算属性  */
  computed: {
  },
  /**  方法集合  */
  methods: {
       // 姓名隐藏
    formatName(name) {
      return printReceipt.hiddenName(name);
    },
    // 手机号隐藏
    formatPhone(phone) {
      return printReceipt.hiddenPhone(phone);
    },

  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {},
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
}
</script>

<style lang="scss">

.saleCashierReceipt{

 }
</style>
